import { RootAction, State } from 'store/state';
import { RESET, SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { LoadType, MutationType, LoadOptions } from 'store/sagas/apiService/types';
import { LOGOUT, SET_AUTH_MODAL } from 'store/state/app/types';
import { all, call, put, race, select, take, fork, getContext } from 'redux-saga/effects';
import { noop } from 'lodash';
import { Route } from 'config/routes';
import { TransitionAction } from '../index';
import { AuthModalSource } from 'store/state/app';
import { setAuthModal } from 'store/state/app/actions';
import { AuthenticationModalType, PendingAction, PendingActionType } from 'components/authentication/types';
import { isServer } from 'utils';
import { Saga } from 'redux-saga';
import {
  isAgentSelector,
  isConnectedUserSelector,
  userLoadingSelector,
  userProfileSelector,
  isAgentManagerSelector,
  isAgentConsoleUserSelector,
} from 'store/state/domainData/selectors';
import { MUTATE, SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import {
  User,
  SearchContext,
  IBannersByDocIdVariables,
  AdditionalAdsSearchContext,
  IAdditionalBannersByDocIdVariables,
  IAutocompleteEntry,
  CompletionType, ISearchPoiVariables, IBulletin, ICommercialBulletin,
  DealType,
  IReference,
} from 'utils/entities';
import { queryData } from 'store/sagas/apiService';
import { isResetPasswordTokenValidSelector } from 'store/state/domainData/selectors';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { navigateTo } from 'store/state/router/actions';
import { mutate } from 'store/state/mutationsResponse/actions';
import { marketplaceSelector } from 'store/state/selectors/router';
import _ from 'lodash';
import { mutateWorker } from 'store/sagas/apiService/mutationsWatcher';
import { postLeadCarouselListingsSelector } from 'store/state/domainData/selectors/listingsPostLeadCarousel';
import { SortDirection, SortField } from 'components/listing-sort/types';


type SetAuthModalAction = ReturnType<typeof setAuthModal>;

export const setUserPattern = (action: RootAction) => (
  (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.CurrentUser)
  || (action.type === RESET && action.payload.loadType === LoadType.CurrentUser)
  || (action.type === LOGOUT)
  || (action.type === MUTATE)
  || (action.type === SET_MUTATION_RESPONSE)
);

export function* waitForUserChange(comparator = (a: User, b: User) => a === b) {
  const prevUser: User = yield select(userProfileSelector);
  let nextUser = prevUser;
  while (comparator(prevUser, nextUser)) {
    yield take(setUserPattern);
    nextUser = yield select(userProfileSelector);
  }
  return nextUser;
}

export const closeAuthModalPattern = (action: RootAction): action is SetAuthModalAction => (
  action.type === SET_AUTH_MODAL && action.payload.type === null
);

export const userInitiatedCloseAuthModalPattern = (action: RootAction) => (
  closeAuthModalPattern(action) && action.meta.isUserInitiated
);


export function* waitForUserResolve() {
  const isUserLoading: boolean = yield select(userLoadingSelector);

  if (isUserLoading) {
    yield take(setUserPattern);
  }
  return true;
}

export const makeTransitionPattern = (route: Route) => (action: TransitionAction) => (
  action.type === TRANSITION_SUCCESS && action.payload.route.name === route
);

export enum AuthGateResponse {
  Rejected = 'rejected',
  Resolved = 'resolved',
  Skipped = 'skipped',
}

type AuthModalRaceActions = [ SetAuthModalAction, [ SetAuthModalAction, unknown ] ];

export function* getAuthGateResponse() {
  const response: AuthGateResponse = yield call(authGateFlow, null, isConnectedUserSelector);
  return response;
}

const completionTypeLevelList: Set<CompletionType> = new Set([ CompletionType.City, CompletionType.Country, CompletionType.CustomZone ]);

export function getCityDocIdFromDocument(entries: IAutocompleteEntry[]): string[] {
  return (entries || []).map(e => {
    if (completionTypeLevelList.has(e.type)) return e.docId;

    if (e.city && e.hierarchyLevel.length) {
      const cityEntry = e.hierarchyLevel.find(h => h.text === e.city);
      return cityEntry ? cityEntry.docId : null;
    }
    return null;
  }).filter(Boolean);
}

const mapPendingActionTypeToSource = (pendingAction: PendingAction) => {
  if (!pendingAction) return undefined;

  switch (pendingAction.type) {
    case PendingActionType.SaveSearch: {
      switch (pendingAction.meta && pendingAction.meta.source) {
        case 'impact_card':
          return AuthModalSource.ImpactCard;
        case 'insight_expand':
          return AuthModalSource.InsightExpand;
        default:
          return AuthModalSource.SaveSearch;
      }
    }

    case PendingActionType.SaveAddress: {
      switch (pendingAction.meta && pendingAction.meta.source) {
        case 'impact_card':
          return AuthModalSource.ImpactCard;
        case 'insight_expand':
          return AuthModalSource.InsightExpand;
        default:
          return AuthModalSource.TrackAddress;
      }
    }

    case PendingActionType.SaveBulletin:
      return AuthModalSource.SaveListing;

    default:
      return undefined;
  }
};

export function* authGate(
  pendingAction?: PendingAction,
  modalType = AuthenticationModalType.SignUp,
  userSelector: (state: State) => boolean = isConnectedUserSelector,
  modalSource?: AuthModalSource
) {
  const source = modalSource ? modalSource : mapPendingActionTypeToSource(pendingAction);
  const response: AuthGateResponse = yield call(authGateFlow, setAuthModal({ type: modalType, pendingAction }, { isUserInitiated: false, source }), userSelector);
  return response;
}

export function* verifyRessetPasswordToken(token: string) {
  yield call(queryData, {
    loadType: LoadType.VerifyResetPasswordRequest,
    meta: {
      variables: {
        token,
      },
    },
  });

  return yield select(isResetPasswordTokenValidSelector);
}

function* authGateFlow(actionToPut: ReturnType<typeof setAuthModal>, userSelector: (state: State) => boolean) {

  const isAllowedUser: boolean = yield select(userSelector);

  if (!isAllowedUser) {
    if (actionToPut) {
      yield put(actionToPut);
    }

    const [ isClosedWithHands, notClosedWithHands ]: AuthModalRaceActions = yield race([
      take(userInitiatedCloseAuthModalPattern),
      all([
        take(closeAuthModalPattern),
        take(setUserPattern),
      ]),
    ]);

    if (isClosedWithHands) {
      return AuthGateResponse.Rejected;
    }
    else {
      const isAllowed: boolean = yield select(userSelector);
      const [ maybeModalAction ] = notClosedWithHands;
      return !maybeModalAction.meta.isUserInitiated && isAllowed
        ? AuthGateResponse.Resolved
        : AuthGateResponse.Rejected;
    }
  }

  return AuthGateResponse.Skipped;
}

function* noopSaga() {
  yield call(noop);
}

export const withAuthRestrictor = <T extends unknown[]>(routeHandler: Saga = noopSaga, allowSpecificUser?: 'agent' | 'officeManager' | 'agentConsoleUser', authModalType: AuthenticationModalType = AuthenticationModalType.SignIn): Saga => function* (...args: T) {
  yield call(waitForUserResolve);

  let selector = isConnectedUserSelector;
  switch (allowSpecificUser) {
    case 'agent':
      selector = isAgentSelector;
      break;
    case 'officeManager':
      selector = isAgentManagerSelector;
      break;
    case 'agentConsoleUser':
      selector = isAgentConsoleUserSelector;
      break;
  }

  const isAllowed: boolean = yield select(isConnectedUserSelector);
  if (isAllowed) {
    const isAllowSpecificUser = yield select(selector);
    if (isAllowSpecificUser) {
      yield call(routeHandler, ...args);
    }
    else {
      yield put(navigateTo(Route.Home, {}, { replace: true }));
    }
  }
  else {
    const ssr = yield call(isServer);

    if (ssr) {
      yield put(setAuthModal({ type: authModalType }, { isUserInitiated: false }));
    }
    else {
      const status: AuthGateResponse = yield call(authGate, undefined, authModalType, isConnectedUserSelector);
      const isAllowSpecificUserAfterLogin = yield select(selector);
      if (status === AuthGateResponse.Rejected || !isAllowSpecificUserAfterLogin) {
        yield put(navigateTo(Route.Home, {}, { replace: true }));
      }
      else {
        yield call(routeHandler, ...args);
      }
    }
  }
};

export function* queryProjectsAds(docId: string, searchContext: SearchContext) {
  const logger = yield getContext('logger');
  try {
    const variables: IBannersByDocIdVariables = { docId, searchContext };
    yield fork(queryData, {
      loadType: LoadType.BannersByDocId,
      meta: {
        variables,
        errorPolicy: 'all',
      },
    });
  }
  catch (error) {
    logger.error(`Failed to query banners by docId=${docId} and searchContext=${searchContext}.`, error);
  }
}

export function* querySearchPageProjectsAds(docId: string, searchContext: SearchContext) {
  const variables: IBannersByDocIdVariables = { docId, searchContext };

  yield fork(queryData, {
    loadType: LoadType.SearchPageBannersByDocId,
    meta: {
      variables,
      errorPolicy: 'all',
    },
  });
}

export function* queryAdditionalAds(docId: string, searchContext: AdditionalAdsSearchContext) {
  const variables: IAdditionalBannersByDocIdVariables = { docId, searchContext };

  yield fork(queryData, {
    loadType: LoadType.AdditionalBannersByDocId,
    meta: {
      variables,
      errorPolicy: 'all',
    },
  });
}

export function* fetchArticles(docIds: string[] = null, key: string = null) {
  const marketplace = yield select(marketplaceSelector);
  yield call(mutateWorker, mutate({
    mutationType: MutationType.GetArticles,
    meta: {
      key,
      variables: {
        query: {
          docIds,
          marketplaceType: _.toUpper(marketplace),
        },
      },
    },
  }));
}

export function* fetchPostLeadCarousel(poi: IBulletin | ICommercialBulletin, selector: (a1: State) => ISearchPoiVariables) {
  if (!poi) return;

  const variables = yield select(selector);
  const locationDocId = poi.addressDetails.cityDocId;

  const searchAdditionalListings: LoadOptions<LoadType.ListingPostLeadCarouselSearch> = {
    loadType: LoadType.ListingPostLeadCarouselSearch,
    meta: {
      variables,
    },
  };
  yield call(queryData, searchAdditionalListings);

  const listings = yield select(postLeadCarouselListingsSelector);

  if ((!listings || !listings.length ) && locationDocId !== variables.locationDocId) {
    const citySearchAdditionalListings: LoadOptions<LoadType.ListingPostLeadCarouselSearch> = {
      loadType: LoadType.ListingPostLeadCarouselSearch,
      meta: {
        variables: {
          ...variables,
          locationDocId,
        },
      },
    };
    yield call(queryData, citySearchAdditionalListings);
  }
}

export const createPoiSearchVariablesForSoldPage = (docId: string, reference: IReference, poiType: 'bulletin' | 'project'): ISearchPoiVariables => {
  return {
    dealType: DealType.Buy,
    tileRanges: null,
    poiTypes: [ poiType ],
    sort: [ { field: SortField.Geometry, order: SortDirection.Asc, reference, docIds: [ docId ] } ],
    limit: 20,
    searchContext: 'information',
  };
};
