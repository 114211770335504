import { addressDocSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { createSelector } from 'reselect';
import { flow, pick } from 'lodash';
import { IAutocompleteAddress } from 'utils/entities';

export const addressInfoEntrySelector = makeNullSafeDomainGetter(addressDocSelector, 'docId2Information');
export const addressDocEntrySelector = flow(addressInfoEntrySelector, (info) => info && info.document);
export const addressReferenceSelector = flow(addressDocEntrySelector, (document) => document && document.reference);


export const isAddressDocLoadingSelector = createIsLoadingSelector(addressDocSelector);

export const addressBuildingMetaSelector = createSelector(
  [ addressDocEntrySelector ],
  (addressDoc) => addressDoc ? pick(addressDoc, [ 'constructionYear', 'unitsTotal', 'floorCount' ]) : null
);
export const addressDocIdSelector = flow(addressDocEntrySelector, (addressDoc) => (
  addressDoc
    ? addressDoc.identityDocId
    : null
));

export const neighborhoodDocIdSelector = flow(addressDocEntrySelector, (address: IAutocompleteAddress) => (
  address ? address.neighbourhoodDocId : null
));
